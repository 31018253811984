/* eslint-disable react/jsx-max-depth */
import { createContext } from "react";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import client from "apollo/client";
import { RecoilRoot, useRecoilValue } from "recoil";
import "./App.css";
//import theme from "themes/theme";
import StartupOperations from "components/organisms/StartupOperations";
import MetaDecorator from "components/templates/MetaDecorator";
// eslint-disable-next-line import/no-extraneous-dependencies
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// eslint-disable-next-line import/no-extraneous-dependencies
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { LicenseInfo } from "@mui/x-license-pro";
import { AuthenticationProvider } from "authentication/AuthenticationCompontent";
import { RecoilDevTools, RecoilTunnel } from "recoil-toolkit";
import { useCustomThemeManager } from "themes/useCustomTheme";
import { Router } from "./routes";
import { HelmetProvider } from "react-helmet-async";
import ExportTextsComponent from "translations/exportTexts/ExportTextsComponent";
import ErrorComponent from "globalErrorhandling/ErrorComponent";
import { languageAtom } from "translations/recoilState";
import { de, enUS } from "date-fns/locale";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_DATAGRID_KEY ?? "");
export const ColorModeContext = createContext({ toggleColorMode: () => {} });
export const LanguageContext = createContext("DE");

const AppInner = () => {
  const language = useRecoilValue(languageAtom);
  const { colorMode, theme } = useCustomThemeManager();
  return (
    <LanguageContext.Provider value={language}>
      <StyledEngineProvider injectFirst>
        <HelmetProvider>
          <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
              <LocalizationProvider adapterLocale={language === "DE" ? de : enUS} dateAdapter={AdapterDateFns}>
                {/* <ErrorBoundary> */}
                <AuthenticationProvider>
                  <StartupOperations>
                    <ExportTextsComponent />
                    <CssBaseline />
                    <MetaDecorator />
                    <ErrorComponent />
                    <Router />
                    <RecoilTunnel />
                    {/* <ClickToComponent />
                     */}
                    <RecoilDevTools
                      forceSerialize
                      serializer={(obj: unknown) => {
                        return JSON.stringify(obj, (key, value) => {
                          if (Object.hasOwn(value ?? {}, "$$typeof")) return "React.element";
                          return value;
                        });
                      }}
                    />
                  </StartupOperations>
                </AuthenticationProvider>
                {/* </ErrorBoundary> */}
              </LocalizationProvider>
            </ThemeProvider>
          </ColorModeContext.Provider>
        </HelmetProvider>
      </StyledEngineProvider>
    </LanguageContext.Provider>
  );
};

function App() {
  return (
    // <AuthenticationCompontent>
    //   <AuthorizationComponent>
    <RecoilRoot>
      <ApolloProvider client={client}>
        <AppInner />
      </ApolloProvider>
    </RecoilRoot>
    //   </AuthorizationComponent>
    // </AuthenticationCompontent>
  );
}

export default App;
