import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import MainNavBar from "components/organisms/MainNavBar";
import React from "react";
import { Link } from "react-router-dom";
import { translations } from "translations";
import { HeaderSC, ToolsAndSearchSC } from "../styles";
import SimpleSearch from "./SimpleSearch";
import Toolbox from "./Toolbox";
interface HeaderProps {
  brand?: React.ReactNode;
  hideBreadcrumb?: boolean;
  hideSimpleSearch?: boolean;
  tools?: React.FC[];
}

const Header: React.FC<HeaderProps> = ({ brand, hideBreadcrumb = false, hideSimpleSearch = false, tools = [] }) => {
  return (
    <HeaderSC className="headerElement" role="banner">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          //  minHeight: "90px",
          width: "500px",
        }}
      >
        <IconButton component={Link} to="/" disableRipple aria-label={translations.globals.button.linkToHome}>
          {brand}
        </IconButton>
        {/* <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <Typography variant="subtitle2">{translations.globals.headlines.fundedBy}</Typography>
          <BMWKLogo />
        </Box> */}
      </Box>
      <MainNavBar />
      <ToolsAndSearchSC>
        <Toolbox tools={tools} />
        {!hideSimpleSearch && <SimpleSearch />}
      </ToolsAndSearchSC>
    </HeaderSC>
  );
};

export default Header;
