import { nextLine } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  address: `
    FGW e.V. - Fördergesellschaft Windenergie und andere Dezentrale Energien
    ${nextLine()}Oranienburger Str. 45
    ${nextLine()}10117 Berlin
  `,
  contact: "Contact",
  footerTitle: "FGW e.V. - Fördergesellschaft Windenergie und andere Dezentrale Energien",
  copyrightString: "© 2024 FGW e.V. All rights reserved.",
  eMail: "info@zerez.net",
  eula: "EULA",
  faq: "FAQ",
  fundedBy: "Funded by (Funding code: 03MAP427)",
  fundingCode: "Funding code: 03MAP427",
  imprint: "Imprint",
  phone: "+49 (0)30 / 30 10 15 05-0",
  privacyPolicy: "Privacy Policy",
  runBy: "Run By",
};
