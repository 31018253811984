import { nextLine, bold } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  certificateUploadProcess: {
    createNew: {
      description:
        "Upload a new certificate. You can enter the data manually or upload a file with the grid connection values.",
      title: "New Certificate",
    },
    replaceExisting: {
      description:
        "Withdraw a certificate and replace it with a new certificate. (Only applies to standards VDE-AR-N 4110, VDE-AR-N 4120 and VDE-AR-N 4130.)",
      title: "Replace Existing Certificate",
    },
    edit: {
      description: "Edit an existing certificate.",
      title: "Edit existing certificate",
    },
    import: {
      description: "Bearbeiten Sie ein migriertes Zertifikat.",
      title: "Edit migrated certificate",
    },
    replaceCertificatePropmt: "Enter the certificate number of the certificate that you want to replace",
  },
  noOptionsText: "No certificates found to replace",
  addUnit: "Add Unit",
  createUnit: "Create Unit",
  linkexistingUnit: "Link existing Unit",
  linkexistingUnitDescription: "Enter model name or unit number",
  animationTitle: "...loading",
  category: "Certificate Category",
  certificationAuthority: "Certification Body",
  certificateIssueDate: "Certificate valid from",
  comment:
    "Please review the scanned data from the uploaded data sheet and make any necessary adjustments in unit parameters. Press the 'Submit' button for confirmation.",
  dataSheet: "Data Sheet",
  discardChanges: "Discard changes?",
  docs: "Uploaded Documents",
  errorMessages: {
    categoryInvalidForNorm: "This category is not valid for this Norm!",
    categoryRequired: "The Category is required!",
    someUnitsHaveErrors: "Some units have errors",
    certificateAlreadyExists: "This Certificate already exists",
    certificateCoverSheetRequired: "The Certificate cover sheet is required",
    perturbationsTestReportRequired: "The Certificate/Perturbations Test Report is required",
    softwareVersionRequired: "The Software Version is required",
    manufacturerUnitAlreadyExists: "This Manufacturer already has a unit with this name",
    manufactucturerIdDifferentToUnitManufacturer: "The Manufacturer does not match the linked unit(s) manufacturer",
    modelAlreadyExists: "This Model already exists",
    normRequired: "The Norm is required",
    requieredIfGreater10: "This field is required if Norm is greate or equal to 10",
    unitHasCountErrors: "The unit {unitName} has {errorCount} errors",
    duplicateUnitNames: "A certificate cannot have multiple units with the same name",
    requieredIfCategory: "This field is required for the combination of Norm & Category",
    requieredIfCategoryAndMaxRated: "This field is required for the combination of Norm, Category & Max Rated Power",
    tooManyFractionalDigits:
      "Input does not meet the format requirements, maximum {maxDecPlaces} decimal places allowed.",
    withTG8Rev25ConformityOnNorm5: "This field is only valid for Norms greater or equal to 10",
    withTG8Rev25ConformityRequired: "This field is required if TG8 Rev. 25 Conformity is active",
    required: "This field is required",
  },
  field: {
    category: "Certificate Category",
    certificationAuthority: "Certification Body",
    certificateIssueDate: "Certificate valid from",
    manufacturer: "Manufacturer",
    validityStatus: "Status",
  },
  feedBack: {
    error: {
      title: "Certificate Creation Error",
      body: "There was a problem creating the certificate. Please try again.",
    },
    success: {
      title: "Certificate Created",
      body: "The certificate and associated units were successfully uploaded.",
    },
    successReplace: {
      title: "Certificate Replaced",
      body: "The new certificate and associated units were successfully uploaded. The previous certificate was successfully replaced.",
    },
    successEdit: {
      title: "Certificate Edited",
      body: "The certificate was successfully edited.",
    },
  },
  info: "Certificate Info",
  label: "Upload Certificate",
  pdfCertificate: "PDF Certificate",
  renameFile: "Rename File",
  resetButtonLabel: "Reset Changes",
  title: "Units & Parameters",
  unsavedChanges:
    "You have commited unsaved changes. If you leave the upload process all changes will be lost. Do you want to leave and discard all changes?",
  uploadFormDescription:
    "You can either upload an Excel file for automatic data scanning. Then check the correctness of the data and click submit. Alternatively, manually input the required data, double-check, and submit the form.",
  updateFormDescription: {
    import: `You can either copy the parameters using an Excel template or enter them manually. Then check that the data is correct and submit the form.
        ${nextLine()}${bold("Please note:")}${nextLine()}
        When uploading an Excel template, only the performance parameters (1.20 - 1.68) are transferred for migrated certificates. All values relating to the certificate (1.01 - 1.19) are ignored.
        ${nextLine()}These may have to be adjusted manually.
        ${nextLine()}The certificate loses the attribute “migrated” when it is edited for the first time.
      `,
  },
  uploadFormTitle: "Upload Certificate",
  noUnitsFound: "No matching units found",
};
