import React from "react";
import { ToolboxSC } from "../styles";
import styled from "@emotion/styled";
import { OverridableComponent, OverridableTypeMap } from "@mui/material/OverridableComponent";

interface ToolboxProps {
  tools: Array<React.FC | OverridableComponent<OverridableTypeMap>>;
}

const StyledSearchIconWrapper = styled("div")({ display: "flex", marginRight: "22px" });

const Toolbox: React.FC<ToolboxProps> = (props) => {
  return (
    <ToolboxSC className="headerElement">
      {props.tools.map((tool, index) => (
        <StyledSearchIconWrapper key={index}>{tool(props)}</StyledSearchIconWrapper>
      ))}
    </ToolboxSC>
  );
};

export default Toolbox;
