
        import { TypePolicies } from "@apollo/client";
        const typePolicies:TypePolicies ={
          Query: {
            fields: {
              accessRequest: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'AccessRequest',
                      id: args.id,
                    });
                  }
                },
              certificateBridgeCertificateNormIssueDate: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'CertificateBridgeCertificateNormIssueDate',
                      id: args.id,
                    });
                  }
                },
              certificateBridgeSoftwareVersion: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'CertificateBridgeSoftwareVersion',
                      id: args.id,
                    });
                  }
                },
              certificateBridgeUnit: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'CertificateBridgeUnit',
                      id: args.id,
                    });
                  }
                },
              certificate: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'Certificate',
                      id: args.id,
                    });
                  }
                },
              certificateFile: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'CertificateFile',
                      id: args.id,
                    });
                  }
                },
              certificateFileType: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'CertificateFileType',
                      id: args.id,
                    });
                  }
                },
              certificateNormBridge: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'CertificateNormBridge',
                      id: args.id,
                    });
                  }
                },
              certificateNorm: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'CertificateNorm',
                      id: args.id,
                    });
                  }
                },
              certificateNormIssueDate: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'CertificateNormIssueDate',
                      id: args.id,
                    });
                  }
                },
              certificateType: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'CertificateType',
                      id: args.id,
                    });
                  }
                },
              certificateValidityStatus: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'CertificateValidityStatus',
                      id: args.id,
                    });
                  }
                },
              customCertificate: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'CustomCertificate',
                      id: args.id,
                    });
                  }
                },
              customCertificateFile: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'CustomCertificateFile',
                      id: args.id,
                    });
                  }
                },
              issue: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'Issue',
                      id: args.id,
                    });
                  }
                },
              issueHistoryBridgeLedger: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'IssueHistoryBridgeLedger',
                      id: args.id,
                    });
                  }
                },
              issueHistory: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'IssueHistory',
                      id: args.id,
                    });
                  }
                },
              issueStatus: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'IssueStatus',
                      id: args.id,
                    });
                  }
                },
              issueSubType: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'IssueSubType',
                      id: args.id,
                    });
                  }
                },
              issueType: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'IssueType',
                      id: args.id,
                    });
                  }
                },
              notification: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'Notification',
                      id: args.id,
                    });
                  }
                },
              notificationEvent: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'NotificationEvent',
                      id: args.id,
                    });
                  }
                },
              notificationMethod: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'NotificationMethod',
                      id: args.id,
                    });
                  }
                },
              notificationRule: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'NotificationRule',
                      id: args.id,
                    });
                  }
                },
              roleBridgeScope: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'RoleBridgeScope',
                      id: args.id,
                    });
                  }
                },
              role: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'Role',
                      id: args.id,
                    });
                  }
                },
              softwareVersion: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'SoftwareVersion',
                      id: args.id,
                    });
                  }
                },
              subscription: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'Subscription',
                      id: args.id,
                    });
                  }
                },
              tenant: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'Tenant',
                      id: args.id,
                    });
                  }
                },
              topic: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'Topic',
                      id: args.id,
                    });
                  }
                },
              unitBridgeUser: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'UnitBridgeUser',
                      id: args.id,
                    });
                  }
                },
              unit: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'Unit',
                      id: args.id,
                    });
                  }
                },
              unitType: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'UnitType',
                      id: args.id,
                    });
                  }
                },
              userBridgeRole: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'UserBridgeRole',
                      id: args.id,
                    });
                  }
                },
              userBridgeTenant: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'UserBridgeTenant',
                      id: args.id,
                    });
                  }
                },
              user: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'User',
                      id: args.id,
                    });
                  }
                },
          }
        }
      }
      export default typePolicies
      