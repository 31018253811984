import { ReactComponent as FgwLogo } from "components/icons/iconsSvg/fgwLogo.svg";
import React from "react";
import { translations } from "translations";
import { StyledFooterHeaderBox, StyledFooterTitleTypography } from "./styles";

interface Props {}

const FooterHeader: React.FC<Props> = () => {
  return (
    <StyledFooterHeaderBox>
      <FgwLogo height="28px" />
      <StyledFooterTitleTypography>{translations.globals.footer.footerTitle}</StyledFooterTitleTypography>
    </StyledFooterHeaderBox>
  );
};

export default FooterHeader;
