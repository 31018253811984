import { ReactComponent as BMWKLogo } from "components/icons/iconsSvg/BMWK_Logo.svg";
import translations from "translations";
import { FooterDocumentsContent } from "./FooterDocumentsContent";
import { FooterInfoBoxContent, FooterInfoBoxContentProps } from "./FooterInfoBoxContent";
import {
  StyledFooterRoot,
  StyledFooterContainer,
  StyledFooterInfoBoxContainer,
  StyledDivider,
  StyledFooterDocumentsContainer,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import FooterHeader from "./FooterHeader";

const getFoundedByContent = (): FooterInfoBoxContentProps => [
  {
    headerTitle: translations.globals.footer.fundedBy,
    content: [
      {
        icon: (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <BMWKLogo />
          </Box>
        ),
      },
    ],
  },
];

const getDocumentsContent = ({
  handleImprint,
  handlePrivatePolicy,
  handleFaq,
  handleContact,
}: {
  handleImprint?: React.MouseEventHandler<HTMLDivElement>;
  handlePrivatePolicy?: React.MouseEventHandler<HTMLDivElement>;
  handleFaq?: React.MouseEventHandler<HTMLDivElement>;
  handleContact?: React.MouseEventHandler<HTMLDivElement>;
}) => {
  return [
    { label: translations.globals.footer.imprint, onClick: handleImprint ?? (() => null) },
    { label: translations.globals.footer.faq, onClick: handleFaq ?? (() => null) },
    // { label: translations.globals.footer.contact, onClick: handleContact ?? (() => null) },
    { label: translations.globals.footer.privacyPolicy, onClick: handlePrivatePolicy ?? (() => null) },
    // { label: translations.globals.footer.eula, onClick: handleEula ?? (() => null) },
    //TODO waiting for response from fgw to add agb
  ];
};

const Footer = () => {
  const navigate = useNavigate();
  const handleFaq = () => navigate("/faq");
  const handleImprint = () => navigate("/imprint");
  const handlePrivatePolicy = () => navigate("/privacypolicy");
  // const handleContact = () => navigate("/contact");

  return (
    <StyledFooterRoot>
      <StyledFooterContainer>
        <FooterHeader />
        <StyledFooterInfoBoxContainer>
          <FooterDocumentsContent content={getDocumentsContent({ handleFaq, handleImprint, handlePrivatePolicy })} />
        </StyledFooterInfoBoxContainer>
        <StyledFooterDocumentsContainer>
          <StyledDivider />
          <FooterInfoBoxContent content={getFoundedByContent()} />
        </StyledFooterDocumentsContainer>
      </StyledFooterContainer>
    </StyledFooterRoot>
  );
};

export default Footer;
