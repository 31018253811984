import { SxProps } from "@mui/material";
import { logout } from "authentication";
import ordinaryColors from "themes/ordinaryColors";
import MinModal from "components/molecules/Modal/MinModal";
import { ReactComponent as ProfileIcon } from "components/icons/iconsSvg/userProfile.svg";
import { ReactComponent as SettingsIcon } from "components/icons/iconsSvg/notificationsSettings.svg";
import { ReactComponent as LogOutIcon } from "components/icons/iconsSvg/logoutOutlined.svg";
import { StyledUserMenuIcon, StyledUserMenuContent, StyledUserMenuLabel } from "./styles";
import translations from "translations";

export const userMenuStyling = {
  alignItems: "flex-start",
  "& .MuiBackdrop-root": { backgroundColor: "transparent" },
};

export const contentStyling = {
  display: "flex",
  // maxWidth: "275px",
  flexDirection: "column",
  gap: "12px",
  alignItems: "flex-start",
  padding: "8px 4px",
  background: ordinaryColors.functionalWhite,
  position: "absolute",
  top: "110px",
  right: "30px",
  boxShadow:
    "0px 1px 14px 0px rgba(0, 0, 0, 0.12), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)",
};

interface UserMenuItemProps {
  handleProfileClick?: () => void;
  handleNotificationsClick?: () => void;
  handleRegistrationClick?: () => void;
  handleLogInClick?: () => void;
}

type UserMenuItem = { icon: JSX.Element; label: string; handleClick: () => void; hideItem?: boolean };

const getUserMenuItems = ({ handleProfileClick, handleNotificationsClick }: UserMenuItemProps): UserMenuItem[] => [
  {
    icon: <ProfileIcon />,
    label: translations.pages.userMenu.myProfile,
    handleClick: handleProfileClick ?? (() => null),
  },
  {
    icon: <SettingsIcon />,
    label: translations.pages.userMenu.notificationSettings,
    handleClick: handleNotificationsClick ?? (() => null),
    // hideItem: true,
  },
  { icon: <LogOutIcon />, label: translations.globals.button.logOut, handleClick: () => logout() },
];

const getStartMenuItems = ({ handleRegistrationClick, handleLogInClick }: UserMenuItemProps) => [
  { label: translations.pages.userMenu.userRegistration, handleClick: handleRegistrationClick ?? (() => null) },

  { label: translations.globals.button.logIn, handleClick: handleLogInClick ?? (() => null) },
];

interface UserMenuContentProps extends UserMenuItemProps {
  handleModal: () => void;
  isUserLoggedIn: boolean;
  logIn: () => Promise<void>;
  signUp: () => Promise<void>;
}
const getUserMenuContent = ({
  handleProfileClick,
  handleNotificationsClick,
  handleModal,
  isUserLoggedIn,
  logIn,
  signUp,
}: UserMenuContentProps) => (
  <>
    {isUserLoggedIn
      ? getUserMenuItems({
          handleProfileClick,
          handleNotificationsClick,
        }).map((item, index) => (
          <StyledUserMenuContent
            key={index}
            hidden={item.hideItem ?? false}
            onClick={() => {
              item.handleClick();
              handleModal();
            }}
          >
            <StyledUserMenuIcon>{item.icon}</StyledUserMenuIcon>
            <StyledUserMenuLabel>{item.label}</StyledUserMenuLabel>
          </StyledUserMenuContent>
        ))
      : getStartMenuItems({ handleLogInClick: logIn, handleRegistrationClick: signUp }).map((item, index) => (
          <StyledUserMenuContent
            key={index}
            onClick={() => {
              item.handleClick();
              handleModal();
            }}
          >
            <StyledUserMenuLabel>{item.label}</StyledUserMenuLabel>
          </StyledUserMenuContent>
        ))}
  </>
);

interface UserMenuProps extends UserMenuContentProps {
  open: boolean;
  sx?: SxProps;
  style?: SxProps;
}

const UserMenu = ({
  handleModal,
  open,
  sx,
  style,
  handleProfileClick,
  handleNotificationsClick,
  isUserLoggedIn,
  logIn,
  signUp,
}: UserMenuProps) => (
  <MinModal
    handleModal={handleModal}
    open={open}
    sx={sx}
    style={style}
    children={getUserMenuContent({
      handleProfileClick,
      handleNotificationsClick,
      handleModal,
      isUserLoggedIn,
      logIn,
      signUp,
    })}
  />
);

export default UserMenu;
