export default {
  plural: "Users",
  columns: {
    userName: "User",
    status: "Status",
    approved: "Approved",
    pending: "Pending",
    declined: "Declined",
    contact: "Contact",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    emailAdress: "Email address",
    tenant: "Tenant",
    role: "Role",
    roles: "Roles",
    registered: "Registered",
    displayName: "Display Name",
    createdAt: "Created at",
  },
};
