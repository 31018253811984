import { de } from "date-fns/locale";
import { create } from "lodash";
export default {
  plural: "Benutzer",
  columns: {
    userName: "Benutzer",
    status: "Status",
    approved: "Genehmigt",
    pending: "Ausstehend",
    declined: "Abgelehnt",
    firstName: "Vorname",
    lastName: "Nachname",
    contact: "Kontakt",
    email: "Email",
    emailAdress: "E-Mail Adresse",
    tenant: "Organisation",
    role: "Rolle",
    roles: "Rollen",
    registered: "Registriert",
    displayName: "Anzeigename",
    createdAt: "Erstellt am",
  },
};
