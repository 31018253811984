import { StyledFooterDocumentsWrapper, StyledFooterSelectionBox, StyledFooterSelectionItem } from "./styles";

export const FooterDocumentsContent = ({
  content,
}: {
  content: { label: string; onClick?: React.MouseEventHandler<HTMLDivElement> }[];
}) => (
  <>
    <StyledFooterDocumentsWrapper>
      <StyledFooterSelectionBox>
        {content.map(({ label, onClick }) => (
          <StyledFooterSelectionItem key={label} onClick={onClick}>
            {label}
          </StyledFooterSelectionItem>
        ))}
      </StyledFooterSelectionBox>
    </StyledFooterDocumentsWrapper>
  </>
);
